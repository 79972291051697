import React from 'react';
import { Text } from '../../../globalStyles';
import { useOrganizationCareFlowsQuery, EditOrganizationHandbookInput } from '../../../graphQL';
import { EditHandbookInput } from '../EditHandbookInput';
import { SectionRow, SectionRowItem } from './SectionRow';
import type { EdibleSectionProps, EditableSectionRowInfo, HandBookInfo } from './types';

type Props = EdibleSectionProps & {
  organizationId: number;
  handbookInfo: HandBookInfo;
  editableSectionRowInfo: EditableSectionRowInfo[];
  submitFunction: (
    editFieldName: keyof EditOrganizationHandbookInput
  ) => (value: string) => Promise<void>;
  verticalDisplay?: boolean;
};

export const titleCareAndCollaboration = 'Care & Collaboration';

export function CareAndCollaborationSection({
  verticalDisplay,
  organizationId,
  handbookInfo,
  selectedField,
  onCancelEdit,
  onStartEdit,
  onSave,
  editableSectionRowInfo,
}: Props) {
  const { data } = useOrganizationCareFlowsQuery({
    variables: { organizationId },
  });

  const orgCareFlows = data?.organization.careFlows;
  const orgCareTypes = orgCareFlows?.map(careFlow => careFlow.careType).join(', ');
  const sponSessionLimitTypeMap = {
    // there are a couple of enums that do this as well, may want to look at using one of them here
    shortTermUncapped: 'Short Term Uncapped',
    shortTermCapped: 'Short Term Capped',
    unrestricted: 'Unrestricted',
    studentAssistanceProgram: 'Student Assistance Program',
  };
  const orgSponSessionLimits = orgCareFlows
    ?.map(careFlow =>
      careFlow.sponsoredSessions
        ? `${careFlow.careType}: ${sponSessionLimitTypeMap[careFlow.sponsoredSessionsLimitType]} (${
            careFlow.sponsoredSessions
          } sessions)`
        : `${careFlow.careType}: ${sponSessionLimitTypeMap[careFlow.sponsoredSessionsLimitType]}`
    )
    .join(', ');

  const editableSectionRows = editableSectionRowInfo.map((row, idx) => (
    <SectionRow
      key={`row-${idx}-${row.label}`}
      label={row.label}
      info={row.info}
      verticalDisplay={verticalDisplay}
    >
      <SectionRowItem onEditClick={onStartEdit ? () => onStartEdit(row.displayKey) : undefined}>
        {selectedField === row.displayKey ? (
          <EditHandbookInput
            handbookRowInfo={String(handbookInfo[row.displayKey])}
            selectedField={row.displayKey}
            onCancel={onCancelEdit}
            onSave={onSave ? value => onSave(row.editInputKey, value) : undefined}
          />
        ) : (
          <Text.bodySmall>{handbookInfo[row.displayKey]}</Text.bodySmall>
        )}
      </SectionRowItem>
    </SectionRow>
  ));

  return (
    <>
      <SectionRow
        verticalDisplay={verticalDisplay}
        label="Sponsored Care Type(s)"
        info="Which care types are offered to this organization's patients?"
      >
        <SectionRowItem>
          <Text.bodySmall>{orgCareTypes}</Text.bodySmall>
        </SectionRowItem>
      </SectionRow>

      <SectionRow label="Sponsored Session Limits" verticalDisplay={verticalDisplay}>
        <SectionRowItem>
          <Text.bodySmall>{orgSponSessionLimits}</Text.bodySmall>
        </SectionRowItem>
      </SectionRow>

      {editableSectionRows}
    </>
  );
}
