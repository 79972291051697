import React from 'react';
import { Text } from '../../../globalStyles';
import { EditHandbookInput } from '../EditHandbookInput';
import { SectionRow, SectionRowItem } from './SectionRow';
import type { EdibleSectionProps, EditableSectionRowInfo, HandBookInfo } from './types';
import { EditOrganizationHandbookInput } from '../../../graphQL';

type Props = EdibleSectionProps & {
  organizationId: number;
  editable?: boolean;
  handbookInfo: HandBookInfo;
  selectedField: keyof HandBookInfo | null;
  setSelectedField: (editFieldName: keyof HandBookInfo | null) => void;
  openEdit: (item: keyof HandBookInfo) => (() => void) | undefined;
  editableSectionRowInfo: EditableSectionRowInfo[] | undefined;
  submitFunction: (
    editFieldName: keyof EditOrganizationHandbookInput
  ) => (value: string) => Promise<void>;
  verticalDisplay?: boolean;
};

export const titleMedical = 'Labs, EKGs, Vitals';

export function MedicalSection({
  handbookInfo,
  editableSectionRowInfo,
  selectedField,
  verticalDisplay,
  onStartEdit,
  onCancelEdit,
  onSave,
}: Props) {
  return (
    <>
      {editableSectionRowInfo?.map(row => (
        <SectionRow verticalDisplay={verticalDisplay} label={row.label} info={row.info}>
          <SectionRowItem onEditClick={onStartEdit ? () => onStartEdit(row.displayKey) : undefined}>
            {selectedField === row.displayKey ? (
              <EditHandbookInput
                handbookRowInfo={String(handbookInfo[row.displayKey])}
                onCancel={onCancelEdit}
                selectedField={row.displayKey}
                onSave={onSave ? value => onSave(row.editInputKey, value) : undefined}
              />
            ) : (
              <Text.bodySmall>{handbookInfo[row.displayKey]}</Text.bodySmall>
            )}
          </SectionRowItem>
        </SectionRow>
      ))}
    </>
  );
}
