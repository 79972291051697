import React, { useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ComponentArgs, EzMultirow } from '../../Components/EzForm';
import { FinalButton } from '../../Components/FinalButton';
import { CheckboxRHF, SelectRHF } from '../../Components/Form';
import { Input, Textarea } from '../../Components/Form/Input';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { Text } from '../../globalStyles';
import {
  CampusOrgMappingInput,
  Entitlement,
  SamlConfigurationType,
  UpdateSamlConfiguration,
  useSamlConfigurationQuery,
  useUpdateSamlConfigurationMutation,
} from '../../graphQL';
import { UnexpectedError } from '../Shared';
import { useOrganizationId } from './util';
import { Warning } from '../../Components/Warning';
import { hasEntitlement } from '../../utils';

// todo: replace this with graphql version
const SamlProfileMatchers = {
  email: 'profileEmailField',
  uniqueId: 'profileUniqueIdField',
} as const;

const defaultValues = {
  entryPoint: '',
  certificates: [],
  profileEmailField: 'email',
  profileUniqueIdField: '',
  profileMatchingField: SamlProfileMatchers.email,
  type: SamlConfigurationType.User,
  authnRequestBinding: '',
  disableRequestedAuthnContext: false,
  issuer: 'mantra-prod',
  enableManualUniqueIdEdits: false,
  automaticallyUpdateEmail: false,
  profileCampusField: '',
  campusOrgMappings: [],
};

export function OrganizationSSOConfig() {
  const organizationId = useOrganizationId();
  const form = useForm<UpdateSamlConfiguration & { type: SamlConfigurationType }>({
    defaultValues,
  });
  const type = form.watch('type');
  const profileMatchingField = form.watch('profileMatchingField');
  const { data, loading, error, refetch } = useSamlConfigurationQuery({
    variables: { organizationId, type },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: result => {
      const { samlConfiguration } = result.organization;
      const fields = [
        'entryPoint',
        'certificates',
        'profileEmailField',
        'profileUniqueIdField',
        'profileMatchingField',
        'issuer',
        'authnRequestBinding',
        'disableRequestedAuthnContext',
        'enableManualUniqueIdEdits',
        'automaticallyUpdateEmail',
        'profileCampusField',
        'campusOrgMappings',
      ] as const;
      fields.forEach(field =>
        form.setValue(field, samlConfiguration?.[field] ?? defaultValues[field])
      );
    },
  });
  const [save, mutation] = useUpdateSamlConfigurationMutation({ onCompleted: () => refetch() });
  const metadataRef = useRef<HTMLTextAreaElement | null>(null);

  if (!data && loading) return <LoadingPage />;
  if (!data || error) return <UnexpectedError />;

  const ssoEntitlementEnabled = hasEntitlement(data.organization, Entitlement.Sso);

  // Since we hide uniqueId when matching field is set to email, let's clear it out at the same time
  if (profileMatchingField === SamlProfileMatchers.email) {
    form.setValue('profileUniqueIdField', '');
  }

  const handleSave = form.handleSubmit(async values => {
    if (values.campusOrgMappings && values.campusOrgMappings.length > 0) {
      values.campusOrgMappings = values.campusOrgMappings.map(mapping => ({
        campus: mapping.campus,
        organizationId: mapping.organizationId,
      }));
    }

    const update = {
      entryPoint: values.entryPoint,
      profileEmailField: values.profileEmailField,
      profileUniqueIdField: values.profileUniqueIdField,
      profileMatchingField: values.profileMatchingField,
      issuer: values.issuer,
      authnRequestBinding: values.authnRequestBinding,
      disableRequestedAuthnContext: values.disableRequestedAuthnContext,
      certificates: values.certificates.map(cert =>
        // strip headers, footers, and newlines from certificates
        cert.replace(/-----.*-----/g, '').replace(/\n/g, '')
      ),
      enableManualUniqueIdEdits: values.enableManualUniqueIdEdits,
      automaticallyUpdateEmail: values.automaticallyUpdateEmail,
      profileCampusField: values.profileCampusField,
      campusOrgMappings: values.campusOrgMappings,
    };

    save({ variables: { organizationId, type: values.type, update } });
  });

  const handleCopyMetadata = () => {
    if (metadataRef.current) {
      metadataRef.current.focus();
      metadataRef.current.select();
      document.execCommand('copy');
    }
  };

  const formType = form.watch('type');

  return (
    <div style={{ maxWidth: 800 }} className="mb4">
      <Text.h2>SSO Configuration</Text.h2>
      {!ssoEntitlementEnabled && (
        <Warning>
          <Text.body>SSO entitlement is disabled. These settings are not in effect</Text.body>
        </Warning>
      )}
      <FormProvider {...form}>
        <label htmlFor="type" className="db mt4 mb2">
          <Text.bodyBold>Type</Text.bodyBold>
        </label>
        <div style={{ maxWidth: 300 }}>
          <SelectRHF
            name="type"
            options={[
              { id: SamlConfigurationType.User, label: 'Patient' },
              { id: SamlConfigurationType.Provider, label: 'MCP User' },
            ]}
          />
        </div>
        <label htmlFor="entryPoint" className="db mt4 mb2">
          <Text.bodyBold>Entry Point</Text.bodyBold>
          <Text.bodySmall>Mantra will use this URL to initiate the login flow.</Text.bodySmall>
        </label>
        <Input
          id="entryPoint"
          name="entryPoint"
          ref={form.register({ required: 'This field is required.' })}
        />
        {form.errors.entryPoint && (
          <Text.bodySmall kind="danger" className="mt1">
            {form.errors.entryPoint.message}
          </Text.bodySmall>
        )}
        <label htmlFor="certificates" className="db mt4 mb2">
          <Text.bodyBold>Certificates</Text.bodyBold>
          <Text.bodySmall>
            X.509 certificates to validate against. Include only the body with no headers or line
            breaks (or just paste with that stuff and we’ll strip it for you).
          </Text.bodySmall>
        </label>
        <Controller
          name="certificates"
          rules={{
            validate: (value: string[]) => value.length > 0 || 'You must provide a certificate.',
          }}
          render={({ value, onChange }) => (
            <EzMultirow values={value} setValues={onChange} blank="" component={Certificate} />
          )}
        />
        {form.errors.certificates && (
          <Text.bodySmall kind="danger" className="mt1">
            {(form.errors.certificates as any).message}
          </Text.bodySmall>
        )}
        <label htmlFor="campusOrgMappings" className="db mt4 mb2">
          <Text.bodyBold>Mapper</Text.bodyBold>
          <Text.bodySmall>Campus Mapper</Text.bodySmall>
        </label>
        <Controller
          name="campusOrgMappings"
          render={({ value, onChange }) => (
            <EzMultirow
              values={value}
              setValues={onChange}
              blank={{ campus: '', organizationId: -1 }}
              component={CampusMappingInput}
            />
          )}
        />
        {/* Working here for New Profile Match */}
        <label htmlFor="profileMatchingField" className="db mt4 mb2">
          <Text.bodyBold>Profile Matching Attribute</Text.bodyBold>
          <Text.bodySmall>
            Which attribute should Mantra use to match an incoming profile: email or partner unique
            ID?
          </Text.bodySmall>
        </label>
        <div style={{ maxWidth: 300 }}>
          <SelectRHF
            name="profileMatchingField"
            options={[
              { id: SamlProfileMatchers.email, label: 'Email Attribute' },
              { id: SamlProfileMatchers.uniqueId, label: 'Unique ID Attribute' },
            ]}
          />
        </div>
        {/* Profile Email Field */}
        <label htmlFor="profileEmailField" className="db mt4 mb2">
          <Text.bodyBold>Profile Email Field</Text.bodyBold>
          <Text.bodySmall>
            Name/oid of email attribute from IdP. Used to match profile if Profile Matching
            Attribute above = email. Required in all cases.
          </Text.bodySmall>
        </label>
        <Input
          id="profileEmailField"
          name="profileEmailField"
          ref={form.register({ required: 'This field is required.' })}
        />
        {profileMatchingField === SamlProfileMatchers.uniqueId && (
          <>
            <label htmlFor="profileUniqueIdField" className="db mt4 mb2">
              <Text.bodyBold>Profile UniqueID Field</Text.bodyBold>
              <Text.bodySmall>
                Name/oid of the uniqueID attribute from IdP. Each partner likely has a different
                name for uniqueId, but any solid unique attribute works. Only used if Profile
                Matching Attribute above = Unique ID
              </Text.bodySmall>
            </label>
            <Input
              id="profileUniqueIdField"
              name="profileUniqueIdField"
              ref={form.register({ required: 'This field is required.' })}
            />
          </>
        )}
        <label htmlFor="profileCampusField" className="db mt4 mb2">
          <Text.bodyBold>Profile Campus Field</Text.bodyBold>
          <Text.bodySmall>
            Name/oid of campus attribute from IdP. Saved for analytics and potentially self
            referral.
          </Text.bodySmall>
        </label>
        <Input id="profileCampusField" name="profileCampusField" ref={form.register()} />

        <label htmlFor="issuer" className="db mt4 mb2">
          <Text.bodyBold>Custom Issuer (optional)</Text.bodyBold>
          <Text.bodySmall>
            A custom entityID. Used in situations where the provider and patient SP must have two
            separate entityIDs. If blank, default is mantra-prod.
          </Text.bodySmall>
        </label>
        <Input id="issuer" name="issuer" ref={form.register()} />
        <label htmlFor="authnRequestBinding" className="db mt4 mb2">
          <Text.bodyBold>Custom Authn Request Binding (optional)</Text.bodyBold>
          <Text.bodySmall>
            Can be used to alter the HTTP request sent to the iDP, i.e. HTTP-POST. If blank, the
            default is HTTP GET.
          </Text.bodySmall>
        </label>
        <Input id="authnRequestBinding" name="authnRequestBinding" ref={form.register()} />
        <label htmlFor="disableRequestedAuthnContext" className="db mt4 mb2">
          <Text.bodyBold>Disable Requested AuthnContext (optional)</Text.bodyBold>
          <Text.bodySmall>
            If true, do not request a specific authentication context. This has helped when the
            passport default requestedAuthnContext is problematic for this IdP.
          </Text.bodySmall>
        </label>
        <CheckboxRHF name="disableRequestedAuthnContext" kind="black" className="mt2" />
        <label htmlFor="enableManualUniqueIdEdits" className="db mt4 mb2">
          <Text.bodyBold>
            Unique Id Matching: Enable manual uniqueId editing in Provider Dashboard
          </Text.bodyBold>
          <Text.bodySmall>
            If true and unique Id matching is used: org mcp staff and oz admins can see and modify
            the saml uniqueId field in the student profile.
          </Text.bodySmall>
        </label>
        <CheckboxRHF
          name="enableManualUniqueIdEdits"
          kind="black"
          className="mt2"
          disabled={formType === SamlConfigurationType.Provider}
        />
        <label htmlFor="automaticallyUpdateEmail" className="db mt4 mb2">
          <Text.bodyBold>UniqueId matching: Disable automatic update of emails.</Text.bodyBold>
          <Text.bodySmall>
            When using unique Id matching, this disables the automatic update of student emails when
            the IdP returns a different email than we have.
          </Text.bodySmall>
        </label>
        <CheckboxRHF
          name="automaticallyUpdateEmail"
          kind="black"
          className="mt2"
          disabled={formType === SamlConfigurationType.Provider}
        />
        <div className="mt5 flex flex-row items-center">
          <FinalButton onClick={handleSave} loading={mutation.loading}>
            Save
          </FinalButton>
          {mutation.data && <Text.body className="ml3">Saved.</Text.body>}
          {mutation.error && <Text.body className="ml3">An error occurred.</Text.body>}
        </div>
      </FormProvider>
      <Text.bodyBold className="mt5">Metadata</Text.bodyBold>
      <Textarea
        readOnly
        value={data.organization.samlConfiguration?.metadata}
        style={{ height: 250, fontFamily: 'monospace', wordBreak: 'break-all' }}
        ref={metadataRef}
        className="mb2"
      />
      <FinalButton onClick={handleCopyMetadata}>Copy</FinalButton>
    </div>
  );
}

function Certificate({ value, onChange }: ComponentArgs<string>) {
  return <Textarea value={value} onChange={e => onChange(e.target.value)} />;
}

export const CampusMappingInput = ({
  value,
  onChange,
  index,
}: ComponentArgs<CampusOrgMappingInput>) => {
  return (
    <div className="w-100 flex">
      <Input
        value={value.campus}
        onChange={e => onChange({ ...value, campus: e.target.value })}
        placeholder={`Campus #${index + 1}`}
        style={{ marginRight: '0.5rem' }}
      />
      <Input
        value={value.organizationId}
        type="number"
        onChange={e => onChange({ ...value, organizationId: parseInt(e.target.value) })}
        placeholder="Organization ID"
      />
    </div>
  );
};
