import { get } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Controller, useFormContext, ValidationRules } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Checkbox } from '.';
import { StateCodes } from '../../graphQL';
import { cx } from '../../utils';
import { useConfigContext } from '../ConfigContext';
import { validatePhoneNumber } from '../FormValidations/Validators';
import { DatePicker, DateRangePicker } from './DatePicker';
import { FormControl, FormControlProps } from './FormControl';
import { Input, InputProps, Textarea, TextareaProps } from './Input';
import { Radio, RadioPill } from './Radio';
import { MultiSelect } from './Select/MantraMultiSelect';
import { NestedSelect } from './Select/MantraNestedSelect';
import { Select, SelectProps } from './Select/MantraSelect';
import { TimeRangeSelect } from './TimeRangeSelect';
import { Toggle } from './Toggle';

type WithRHF<T> = {
  name: string;
  rules?: ValidationRules;
  controlProps?: Omit<FormControlProps, 'name'>;
} & Omit<T, 'onChange' | 'onSelect' | 'value' | 'onBlur'>;

type ControllerRenderProps = Parameters<NonNullable<Parameters<typeof Controller>[0]['render']>>[0];

type FactoryArgs<T extends {}> = {
  component: (props: T) => JSX.Element;
  propMapper: (ctrl: ControllerRenderProps, props: WithRHF<T>) => T;
  defaultDefaultValue?: any;
};
// prettier-ignore
const factoryRHF = <T extends {}>({
  propMapper,
  component: Comp,
  defaultDefaultValue = null,
}: FactoryArgs<T>) => (props: WithRHF<T>) => {
  const { name, rules, controlProps } = props;

  const ctx = useFormContext();
  const error = get(ctx.errors, name);

  return (
    <FormControl name={name} caption={error?.message} {...controlProps} error={!!error}>
      <Controller
        defaultValue={defaultDefaultValue}
        name={name}
        rules={rules}
        render={args => <Comp error={!!error} name={name} {...propMapper(args, props)} />}
      />
    </FormControl>
  );
};

export const DatePickerRHF = factoryRHF({
  component: DatePicker,
  propMapper: ({ value, ...rest }, props) => ({
    value: value ? new Date(value) : null,
    ...rest,
    ...props,
  }),
});

export const DateRangePickerRHF = factoryRHF({
  component: DateRangePicker,
  propMapper: (ctrl, props) => ({ ...ctrl, ...props }),
});

export const ToggleRHF = factoryRHF({
  component: Toggle,
  propMapper: ({ value, ...rest }, props) => ({ toggled: value, ...rest, ...props }),
});

export const CheckboxRHF = factoryRHF({
  component: Checkbox,
  propMapper: ({ value, ...rest }, props) => ({ checked: value, ...rest, ...props }),
  defaultDefaultValue: false,
});

export const MultiSelectRHF = factoryRHF({
  component: MultiSelect,
  propMapper: (ctrl, props) => ({ id: ctrl.name, ...ctrl, ...props }),
});

export const NestedSelectRHF = factoryRHF({
  component: NestedSelect,
  propMapper: (ctrl, props) => ({ id: ctrl.name, ...ctrl, ...props }),
});

export const TimeRangeSelectRHF = factoryRHF({
  component: TimeRangeSelect,
  propMapper: ({ value, ...ctrl }, props) => ({
    id: ctrl.name,
    value: value ?? [null, null],
    ...ctrl,
    ...props,
  }),
});

// @TODO - deprecate defaultValue for formContext default value
export function SelectRHF<T = undefined>({
  name,
  controlProps,
  rules,
  defaultValue,
  ...props
}: WithRHF<SelectProps<T>>) {
  const ctx = useFormContext();
  const error = get(ctx.errors, name);

  return (
    <FormControl name={name} caption={error?.message} {...controlProps} error={!!error}>
      <Controller
        rules={rules}
        name={name}
        defaultValue={defaultValue || get(ctx.control.defaultValuesRef.current, name, null)}
        render={({ value, onChange, onBlur }) => (
          <Select
            id={name}
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            name={name}
            error={!!error}
            {...props}
          />
        )}
      />
    </FormControl>
  );
}

export const InputRHF = ({
  name,
  rules,
  controlProps,
  className,
  type = 'text',
  ...props
}: WithRHF<InputProps>) => {
  const ctx = useFormContext();
  const error = get(ctx.errors, name);

  return (
    <FormControl name={name} caption={error?.message} {...controlProps} error={!!error}>
      <Input
        {...props}
        name={name}
        type={type}
        ref={ctx.register(rules)}
        className={cx(error && 'error', className)}
        aria-invalid={!!error}
        aria-required={!!rules?.required}
      />
    </FormControl>
  );
};

export const TextareaRHF = ({
  name,
  rules,
  controlProps,
  className,
  defaultValue,
  ...props
}: WithRHF<TextareaProps>) => {
  const ctx = useFormContext();
  const error = get(ctx.errors, name);

  return (
    <FormControl name={name} caption={error?.message} {...controlProps} error={!!error}>
      <Textarea
        {...props}
        defaultValue={defaultValue}
        name={name}
        ref={ctx.register(rules)}
        className={cx(error && 'error', className)}
        aria-invalid={!!error}
        aria-required={!!rules?.required}
      />
    </FormControl>
  );
};
interface RadioRHFProps {
  options: { id: string | number | null; label: string }[];
  name: string;
  withLabel?: boolean;
  optionsInline?: boolean;
  optionType?: 'default' | 'pill';
}

export const RadioRHF = ({
  options,
  name,
  rules,
  withLabel,
  controlProps,
  optionsInline,
  optionType = 'default',
}: WithRHF<RadioRHFProps>) => {
  const ctx = useFormContext();
  const error = get(ctx.errors, name);
  return (
    <FormControl name={name} caption={error?.message} {...controlProps} error={!!error}>
      <Controller
        rules={rules}
        name={name}
        render={({ onChange, value }) => (
          <div
            className={cx(
              'mt3 mb1',
              { 'flex flex-row': optionsInline },
              { 'gap-3': optionsInline && optionType !== 'pill' },
              { 'gap-2': optionsInline && optionType === 'pill' }
            )}
          >
            {options.map(option => {
              if (optionType === 'pill') {
                return (
                  <RadioPill
                    key={option.label}
                    onChange={isChecked => {
                      if (isChecked) onChange(option.id);
                    }}
                    label={withLabel ? option.label : undefined}
                    checked={value === option.id}
                    optionId={option.id}
                    name={name}
                  />
                );
              }
              return (
                <Radio
                  key={option.label}
                  onChange={isChecked => {
                    if (isChecked) onChange(option.id);
                  }}
                  label={withLabel ? option.label : undefined}
                  checked={value === option.id}
                />
              );
            })}
          </div>
        )}
      />
    </FormControl>
  );
};

type MaskedInputProps = InputProps & { mask: string };

export const MaskedInputRHF = ({
  name,
  rules,
  controlProps,
  className,
  type = 'text',
  ...props
}: WithRHF<MaskedInputProps>) => {
  const ctx = useFormContext();
  const error = get(ctx.errors, name);
  const defaultValue = get(ctx.control.defaultValuesRef.current, name);

  return (
    <FormControl name={name} caption={error?.message} {...controlProps} error={!!error}>
      <InputMask
        {...props}
        defaultValue={defaultValue}
        name={name}
        type={type}
        className={cx(error && 'error', className)}
        aria-invalid={!!error}
        aria-required={!!rules?.required}
      >
        {(p: any) => <Input {...p} ref={ctx.register(rules)} />}
      </InputMask>
    </FormControl>
  );
};

type PreMaskedInputProps = WithRHF<Omit<MaskedInputProps, 'mask'>>;

export const DateInputRHF = ({ rules, ...props }: PreMaskedInputProps) => (
  <MaskedInputRHF
    mask="99/99/9999"
    rules={{
      validate: {
        validDate: (value: string) =>
          moment(value, 'MM/DD/YYYY').isValid() || 'Please enter a valid date.',
        format: (value: string) =>
          /^\d{2}\/\d{2}\/\d{4}$/.test(value) || 'Enter date in MM/DD/YYYY format.',
        ...(rules?.validate ?? {}),
      },
      ...rules,
    }}
    {...props}
  />
);

export const PhoneInputRHF = ({ rules, ...props }: PreMaskedInputProps) => (
  <MaskedInputRHF
    mask="(999) 999-9999"
    rules={{
      validate: {
        format: validatePhoneNumber,
        ...(rules?.validate ?? {}),
      },
      ...rules,
    }}
    {...props}
  />
);

export const ZipInputRHF = (props: Omit<PreMaskedInputProps, 'type'>) => (
  <MaskedInputRHF mask="99999" type="zip" placeholder="00000" {...props} />
);

export const ServicedStateSelectRHF = (props: WithRHF<Omit<SelectProps<undefined>, 'options'>>) => {
  const { allDtcStates } = useConfigContext();
  return <SelectRHF options={allDtcStates.map(s => ({ id: s, label: s }))} {...props} />;
};

export const StateSelectRHF = (props: WithRHF<Omit<SelectProps<undefined>, 'options'>>) => {
  const stateCodes = Object.values(StateCodes);
  return <SelectRHF options={stateCodes.map(s => ({ id: s, label: s }))} {...props} />;
};
