import { Avatar } from 'baseui/avatar';
import { capitalize, isNumber } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '../../../Components/Booking/Styles';
import { CampusTeamSelection } from '../../../Components/CampusTeam/CampusTeamSelection';
import { EditCampusTeamMember } from '../../../Components/CampusTeam/types';
import { FinalButton } from '../../../Components/FinalButton';
import { DateInputRHF, InputRHF, PhoneInputRHF, SelectRHF } from '../../../Components/Form';
import { Icon, icons } from '../../../Components/Icons';
import { MantraSpinner } from '../../../Components/LoadingOverlay';
import { modalFactory } from '../../../Components/Modal';
import { Notification } from '../../../Components/Notification';
import { useCurrentProvider } from '../../../Components/Permissions';
import { useProviderNetworkContext } from '../../../Components/ProviderNetwork/ProviderNetworkContext';
import { getDisplayableType } from '../../../Components/ProviderNetwork/providerNetworkUtils';
import { AppointmentOption } from '../../../Components/ProviderNetwork/types';
import { When } from '../../../Components/When';
import { Text } from '../../../globalStyles';
import {
  SamlConfigurationType,
  Entitlement,
  StateCodes,
  useAdminCreateUserWithAppointmentMutation,
  useEnrollModalQuery,
  User,
  useSamlConfigurationQuery,
} from '../../../graphQL';
import { getFullName } from '../../../modelUtils/users';
import { stateOptions } from '../../../states';
import { RequireJust } from '../../../types';
import { borderRadius, hasEntitlement, stripPhoneNumber } from '../../../utils';
import { FormFields, Label } from './Common';
import { ReferralCreditsOverview } from '../../../Components/ReferralCredits/ReferralCreditsOverview';
import { ReferralCreditsUsageSufficient } from '../../../Components/ReferralCredits/ReferralCreditsUsageSufficient';
import { ReferralCreditsInsufficient } from '../../../Components/ReferralCredits/ReferralCreditsInsufficient';
import { getReferralCreditBreakdown } from '../../../Components/ReferralCredits/getReferralCreditBreakdown';
import * as ReferralCreditStyles from '../../Students/styles';
import { OutsideReferralPeriod } from '../../../Components/ReferralCredits/OutsideReferralPeriod';
import { parseCampusTeamToMemberInput } from '../../../Components/CampusTeam/campusTeamUtils';

const required = 'This field is required.';

const Modal = modalFactory({
  style: { ...borderRadius('4px'), overflow: 'hidden', maxWidth: '60rem' },
});

export type BookingSuccess = {
  appointment: AppointmentOption;
  user: RequireJust<User, 'firstName' | 'lastName'>;
};

type ModalProps = {
  onClose: () => void;
  appointment: AppointmentOption;
  onComplete: (s: BookingSuccess) => void;
};

export const SponsoredCareModal = ({ onClose, appointment, onComplete }: ModalProps) => {
  const {
    searchVariables: { state },
  } = useProviderNetworkContext();
  const [showPreferredName, setShowPreferredName] = useState(false);
  const { currentProvider } = useCurrentProvider();
  const organizationId = currentProvider.organizations[0].id;
  const [campusTeamError, setCampusTeamError] = useState<string>('');
  const [dobError, setDobError] = useState<boolean>(false);

  const { data: samlConfigurationQueryData } = useSamlConfigurationQuery({
    variables: {
      organizationId,
      type: SamlConfigurationType.User,
    },
  });
  const form = useForm<FormFields>({
    defaultValues: { geoState: state ?? undefined },
  });

  const { description, duration } = getApptDetails(appointment);

  const { data, loading } = useEnrollModalQuery({
    variables: { organizationId, providerIds: [appointment.provider.id] },
  });

  const [campusTeam, setCampusTeam] = useState<EditCampusTeamMember[]>([]);

  const [createUser, mutation] = useAdminCreateUserWithAppointmentMutation({
    onCompleted: res => onComplete({ appointment, user: res.adminCreateUser }),
    onError: error => {
      if (isMinorConsentError(error)) {
        setDobError(true);
      }
    },
  });

  const childOrganizationId = form.watch('childOrganizationId');

  const { setValue } = form;
  useEffect(() => {}, [setValue, childOrganizationId]);

  const onSubmit = form.handleSubmit(values => {
    const campusTeamWithRelations = parseCampusTeamToMemberInput(campusTeam, setCampusTeamError);

    if (!campusTeamWithRelations) {
      return;
    }

    setCampusTeamError('');
    setDobError(false);

    createUser({
      variables: {
        input: {
          organizationId: values.childOrganizationId ?? organizationId,
          firstName: values.firstName,
          lastName: values.lastName,
          preferredName: values.preferredName,
          email: values.email,
          phone: stripPhoneNumber(values.phone),
          birthDate: moment(values.birthDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          campusTeam: campusTeamWithRelations,
          careTypes: [appointment.careType],
          geoState: values.geoState as StateCodes,
          samlUniqueId: values.uniqueId,
        },
        intakeAppointment: {
          startTime: new Date(appointment.startTime.format()),
          endTime: new Date(appointment.endTime.format()),
          providerId: appointment.provider.id,
          // if appointment was created using the dedicated group model allocations, the appointment.organizationId value will be null
          organizationId: appointment.organizationId,
          isFeeForServiceTime: appointment.isFeeForServiceTime,
        },
      },
    });
  });

  const wholeCampusCare = hasEntitlement(
    currentProvider.organizations[0],
    Entitlement.WholeCampusCare
  );

  // These will be undefined for non-WCC or when the component is still loading data
  const {
    availableCredits,
    therapyCreditWeight,
    psychiatryCreditWeight,
    referralPeriodEnd,
    referralCreditCost,
  } = getReferralCreditBreakdown({
    wholeCampusCare,
    orgReferralCreditData: data?.organization?.referralCredits,
    careTypes: [appointment.careType],
  });

  // If the data is finished loading, and it's a WCC org, let's validate that referral credits are available
  if (
    data &&
    wholeCampusCare &&
    (!referralPeriodEnd || !therapyCreditWeight || !psychiatryCreditWeight)
  ) {
    return (
      <Modal isOpen onClose={onClose}>
        <ReferralCreditStyles.Background>
          <ReferralCreditStyles.Wrapper>
            <OutsideReferralPeriod />
          </ReferralCreditStyles.Wrapper>
        </ReferralCreditStyles.Background>
      </Modal>
    );
  }

  /** Show referral credits insufficient component:
   *  If we're in WCC org, compare available credits and referral cost */
  const showReferralCreditsInsufficient =
    wholeCampusCare &&
    (availableCredits === undefined ||
      availableCredits === 0 ||
      referralCreditCost === undefined ||
      availableCredits < referralCreditCost);

  /** Show enrollment form
   * 1. For EC (Non-WCC) always show form
   * 2. For WCC, hide form if referral credits are depleted
   */
  const showEnrollmentForm =
    !wholeCampusCare ||
    (!!availableCredits && !!referralCreditCost && availableCredits >= referralCreditCost);

  return (
    <Modal isOpen onClose={onClose}>
      <FormProvider {...form}>
        <div className="flex">
          <div className="flex-1" style={{ background: '#EFEFEF', padding: '55px 62px' }}>
            <Text.h2 className="mb4">Create student account to book appointment</Text.h2>
            {loading ? (
              <div className="flex justify-center pv3">
                <MantraSpinner size={25} simple />
              </div>
            ) : (
              <div>
                <Text.bodyBold>
                  {description} {`(${duration} min)`}
                </Text.bodyBold>
                <Text.body className="flex items-center">
                  <Icon icon="iconsBlackApptSvg" alt="Calendar" className="mr1" />
                  {appointment.startTime.format('M/D/YYYY h:mm a')} -{' '}
                  {appointment.endTime.clone().format('h:mma z')}
                </Text.body>
                {/* Provider Icon */}
                <div className="mt4 flex flex-row gap-3 items-center">
                  <Avatar
                    size="3rem"
                    overrides={{ Avatar: { style: { maxWidth: '2.5rem' } } }}
                    src={
                      data?.providersById.find(p => p.id === appointment.provider.id)?.portrait
                        ?.url ?? icons.assetsDefaultPicturePng
                    }
                    name={appointment.provider.name}
                  />
                  <div>
                    <Text.h3>
                      {appointment.provider.name.split(' ').map(capitalize).join(' ')}
                    </Text.h3>
                    <Text.bodySmall kind="grayText">
                      {getDisplayableType(appointment.provider)}
                    </Text.bodySmall>
                  </div>
                </div>
                {wholeCampusCare && (
                  <div className="mt4">
                    <ReferralCreditsOverview
                      periodEnd={`${referralPeriodEnd}`}
                      availableCredits={availableCredits}
                      therapyCreditWeight={therapyCreditWeight}
                      psychiatryCreditWeight={psychiatryCreditWeight}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {showReferralCreditsInsufficient && (
            <div className="flex-1" style={{ padding: '55px 62px' }}>
              <ReferralCreditsInsufficient />
            </div>
          )}
          {showEnrollmentForm && (
            <div className="flex-1" style={{ padding: '55px 62px' }}>
              {mutation.error && !isMinorConsentError(mutation.error) && (
                <Notification kind="negative">
                  {mutation.error.message ?? 'An unexpected error occurred.'}
                </Notification>
              )}
              <div className="flex">
                <Label name="firstName" label="First Name" className="mb0">
                  <InputRHF
                    name="firstName"
                    rules={{ required }}
                    controlProps={{ className: 'mb2' }}
                  />
                </Label>
                <Label name="lastName" label="Last Name" className="ml3 mb0">
                  <InputRHF
                    name="lastName"
                    rules={{ required }}
                    controlProps={{ className: 'mb2' }}
                  />
                </Label>
              </div>
              {showPreferredName ? (
                <Label name="preferredName" label="Preferred Name" className="mb0">
                  <InputRHF name="preferredName" />
                </Label>
              ) : (
                <Text.linkButton className="b mb3" onClick={() => setShowPreferredName(true)}>
                  + Add Preferred Name
                </Text.linkButton>
              )}
              <Label name="birthDate" label="Date of Birth">
                <DateInputRHF
                  name="birthDate"
                  rules={{
                    required,
                  }}
                />
                {dobError && (
                  <Text.bodySmallBold kind="dangerBgAlt">
                    Eligible minors under 18 must be referred via our{' '}
                    <Text.link to={`/organizations/${organizationId}/students/enroll`}>
                      standard referral form
                    </Text.link>
                    , as visits cannot be scheduled for minors until the consent process is
                    completed.
                  </Text.bodySmallBold>
                )}
              </Label>
              <Label name="email" label="Student University Email Address">
                <InputRHF name="email" rules={{ required }} />
              </Label>
              <Label name="geoState" label="Student Residential State">
                <SelectRHF
                  name="geoState"
                  options={stateOptions}
                  rules={{
                    required,
                  }}
                />
              </Label>
              <Label name="phone" label="Student Phone Number">
                <PhoneInputRHF name="phone" rules={{ required }} />
              </Label>

              {samlConfigurationQueryData?.organization.samlConfiguration
                ?.enableManualUniqueIdEdits && (
                <Label name="samlUniqueId" label="Unique Id" className="mb0">
                  <InputRHF
                    name="samlUniqueId"
                    rules={{ required }}
                    controlProps={{ className: 'mb2' }}
                  />
                </Label>
              )}

              {data && data.organization.children.length > 0 && (
                <Label name="childOrganizationId" label="Campus">
                  <SelectRHF
                    name="childOrganizationId"
                    options={
                      data?.organization.children.map(org => ({ id: org.id, label: org.name })) ??
                      []
                    }
                    rules={{ required }}
                  />
                </Label>
              )}
              <When isTruthy={isNumber(organizationId)}>
                <CampusTeamSelection
                  campusTeam={campusTeam ?? []}
                  setCampusTeam={setCampusTeam}
                  organizationId={organizationId}
                  relationshipError={campusTeamError}
                />
              </When>
              {wholeCampusCare && (
                <div className="mb4">
                  <ReferralCreditsUsageSufficient
                    referralCost={referralCreditCost}
                    therapyCreditWeight={therapyCreditWeight}
                    psychiatryCreditWeight={psychiatryCreditWeight}
                  />
                </div>
              )}
              <FinalButton
                kind="primary"
                className="w-100"
                type="submit"
                onClick={onSubmit}
                loading={mutation.loading}
                disabled={
                  wholeCampusCare &&
                  availableCredits !== undefined &&
                  referralCreditCost !== undefined &&
                  (availableCredits === 0 || availableCredits < referralCreditCost)
                }
              >
                Submit
              </FinalButton>
            </div>
          )}
        </div>
      </FormProvider>
    </Modal>
  );
};

type EnrollmentSuccessProps = BookingSuccess;

export const EnrollmentSuccess = ({ appointment, user }: EnrollmentSuccessProps) => {
  const history = useHistory();
  const { duration, description } = getApptDetails(appointment);
  return (
    <SuccessWrapper>
      <Icon icon="iconsCircleCheckSvg" alt="Success" className="center db" size={26} />
      <div className="mh2">
        <Text.h2>Appointment booked!</Text.h2>
        <Text.body className="mb4">
          Student will receive a confirmation email with appointment details.
        </Text.body>
        <Text.body>
          This appointment will take place in the student’s Mantra Health account. Please advise
          student to complete onboarding before their appointment, including reviewing &amp;
          agreeing to all releases.
        </Text.body>
        <Box className="mv4">
          <Text.body>{getFullName(user)}</Text.body>
          <Text.body>
            {description} {`(${duration} min) with ${appointment.provider.name}`}
          </Text.body>
          <Text.body className="flex items-center">
            <Icon icon="iconsBlackApptSvg" alt="Calendar" className="mr1" />
            {appointment.startTime.format('M/D/YYYY h:mm a')} -{' '}
            {appointment.endTime.clone().format('h:mma z')}
          </Text.body>
        </Box>
        <FinalButton
          kind="outline_black"
          className="w-100 mb3"
          onClick={() => history.push(`/users/${user.id}`)}
        >
          Go to patient profile
        </FinalButton>
      </div>
    </SuccessWrapper>
  );
};

export const SuccessWrapper = styled.div`
  max-width: 400px;
  margin: 4rem auto;
`;

const getApptDetails = (appointment: AppointmentOption) => {
  const duration = moment(appointment.endTime).diff(appointment.startTime, 'minutes');
  const description =
    appointment.careType === 'Psychiatry'
      ? 'Initial Psychiatric Evaluation'
      : 'Initial Therapy Evaluation';
  return { duration, description };
};

const isMinorConsentError = (error: Error) => error.message === 'Minor consent needed';
