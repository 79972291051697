import { keyBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MantraAccordion, MantraAccordionPanel } from '../../Components/Accordion';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { colors, Text } from '../../globalStyles';
import {
  EditOrganizationHandbookInput,
  OrganizationHandbookQuery,
  Permission,
  useEditOrganizationHandbookMutation,
  useOrganizationHandbookQuery,
  useOrganizationTopLevelQuery,
} from '../../graphQL';
import { useOrganizationId } from '../Organizations/util';
import { UnexpectedError } from '../Shared';
import { HandbookNavigation } from './HandbookNavigation';
import { HANDBOOK_PANELS } from './panels';
import { AccommodationsSection, titleAccommodations } from './Section/AccommodationsSection';
import { CampusResourcesSection, titleCampusResources } from './Section/CampusResourcesSection';
import {
  CareAndCollaborationSection,
  titleCareAndCollaboration,
} from './Section/CareAndCollaborationSection';
import { ContinuityOfCareSection, titleContinuityOfCare } from './Section/ContinuityOfCareSection';
import {
  EmergencyProtocolSection,
  titleEmergencyProtocol,
} from './Section/EmergencyProtocolSection';
import { MedicalSection, titleMedical } from './Section/MedicalSection';

type HandbookProps = {
  organizationId: number;
};

export function HandbookFromParams() {
  const organizationId = useOrganizationId();
  return <Handbook organizationId={organizationId} />;
}

export type HandBookInfo = OrganizationHandbookQuery['organizationHandbook'];
export type EditableSectionRowInfo = {
  label: string;
  info?: string;
  editInputKey: keyof EditOrganizationHandbookInput;
  displayKey: keyof HandBookInfo;
};

type PanelsType = {
  key: string;
  title: string;
  Component: (props: any) => JSX.Element;
  editableSectionRowInfo: EditableSectionRowInfo[];
};

const panels: PanelsType[] = [
  {
    key: 'careAndCollaboration',
    title: titleCareAndCollaboration,
    Component: CareAndCollaborationSection,
    editableSectionRowInfo: [
      {
        label: 'Session Limits Renew Each Academic Year?',
        editInputKey: 'sessionLimitsRenew',
        displayKey: 'sessionLimitsRenew',
      },
      {
        label: 'No Shows and Late Cancellations count toward session limit?',
        info: 'Do patient no-shows and late cancellations (<24hr before appt) result in a deduction of a session?',
        editInputKey: 'noShowsCountForSessionLimits',
        displayKey: 'noShowsCountForSessionLimits',
      },
      {
        label: 'Default Therapy Cadence',
        editInputKey: 'defaultTherapyCadence',
        displayKey: 'defaultTherapyCadence',
      },
      {
        label: 'Co-pay charged by partner for Mantra services?',
        info: 'Does the partner charge a co-pay for Mantra services?',
        editInputKey: 'coPayChargedByPartner',
        displayKey: 'coPayChargedByPartner',
      },
    ],
  },
  {
    key: 'campusResources',
    title: titleCampusResources,
    Component: CampusResourcesSection,
    editableSectionRowInfo: [],
  },
  {
    key: 'accomodations',
    title: titleAccommodations,
    Component: AccommodationsSection,
    editableSectionRowInfo: [
      {
        label: 'Requesting academic accommodations',
        editInputKey: 'requestingAcademicAccomodations',
        displayKey: 'requestingAcademicAccomodations',
      },
      {
        label: 'Full ADHD evaluation',
        editInputKey: 'fullADHDEvaluation',
        displayKey: 'fullADHDEvaluation',
      },
      {
        label: 'Protocol for obtaining Medical Leave documentation',
        editInputKey: 'protocolForMedicalLeaveDoc',
        displayKey: 'protocolForMedicalLeaveDoc',
      },
    ],
  },
  {
    key: 'continuityOfCare',
    title: titleContinuityOfCare,
    Component: ContinuityOfCareSection,
    editableSectionRowInfo: [
      {
        label: 'Summer Sponsored Break: Who is eligible?',
        editInputKey: 'summerBreakEligibility',
        displayKey: 'summerBreakEligibility',
      },
      {
        label: 'Winter Sponsored Break: Who is eligible?',
        editInputKey: 'winterBreakEligibility',
        displayKey: 'winterBreakEligibility',
      },
    ],
  },
  {
    key: 'medical',
    title: titleMedical,
    Component: MedicalSection,
    editableSectionRowInfo: [
      {
        label: 'Labs',
        editInputKey: 'labsNearCampus',
        displayKey: 'labsNearCampus',
      },
      {
        label: 'EKGs',
        editInputKey: 'ekgsNearCampus',
        displayKey: 'ekgsNearCampus',
      },
      {
        label: 'Vitals',
        editInputKey: 'vitalsNearCampus',
        displayKey: 'vitalsNearCampus',
      },
    ],
  },
  {
    key: 'emergencyProtocol',
    title: titleEmergencyProtocol,
    Component: EmergencyProtocolSection,
    editableSectionRowInfo: [
      {
        label: 'High level protocol for managing emergencies',
        editInputKey: 'highLevelProtocolManagingEmergency',
        displayKey: 'highLevelProtocolManagingEmergency',
      },
      {
        label: 'Campus safety/police number',
        editInputKey: 'campusSafetyPoliceNumber',
        displayKey: 'campusSafetyPoliceNumber',
      },
      {
        label: 'Supporting Clinician',
        editInputKey: 'supportingClinician',
        displayKey: 'supportingClinician',
      },
      {
        label: 'Hospitalization',
        editInputKey: 'hospitalization',
        displayKey: 'hospitalization',
      },
      {
        label: 'Main point of contact to inform about the crisis',
        editInputKey: 'mainPointOfContactCrisis',
        displayKey: 'mainPointOfContactCrisis',
      },
      {
        label: 'On-Campus Crisis Team',
        editInputKey: 'campusCrisisTeamInProduct',
        displayKey: 'campusCrisisTeamInProduct',
      },
    ],
  },
];

export const panelLookup = keyBy(panels, panel => panel.key);

export function Handbook({ organizationId }: HandbookProps) {
  const { pathname, hash, key } = useLocation();

  const orgName = useOrganizationTopLevelQuery({ variables: { id: organizationId } }).data
    ?.organization.name;

  const [editOrganizationHandbook] = useEditOrganizationHandbookMutation();
  const { hasPermission } = useCurrentProvider();

  const editable = hasPermission(Permission.MantraAdmin);

  // set the field being edited
  const [selectedField, setSelectedField] = useState<keyof HandBookInfo | null>(null);

  // navigation
  const [activeNav, setActiveNav] = useState(titleCareAndCollaboration);
  const navigationIds = [
    { navId: titleCareAndCollaboration },
    { navId: titleCampusResources },
    { navId: titleAccommodations },
    { navId: titleContinuityOfCare },
    { navId: titleMedical },
    { navId: titleEmergencyProtocol, emergency: true },
  ];

  // populate handbook information
  const { data, refetch, error, loading } = useOrganizationHandbookQuery({
    variables: { organizationId },
  });

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '').replaceAll('%20', ' ');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key, loading]);

  if (loading) return <LoadingPage />;
  if (!data || error) return <UnexpectedError />;
  const handbookInfo = data.organizationHandbook;

  // function for submitting changes to a particular field
  const onSave = async (editFieldName: keyof EditOrganizationHandbookInput, value: string) => {
    await editOrganizationHandbook({
      variables: {
        organizationId,
        input: { [editFieldName]: value.trim() },
      },
    });
    refetch();
  };

  return (
    <HandbookDiv className="mh4 mv4">
      <HandbookNavDiv>
        <Text.h1 className="mr4">{orgName} Handbook</Text.h1>
        <HandbookNavigation
          activeNav={activeNav}
          setActiveNav={setActiveNav}
          navigationIds={navigationIds}
        />
      </HandbookNavDiv>
      <MantraAccordion multi>
        {HANDBOOK_PANELS.map(({ title, Component, editableSectionRowInfo }, idx) => (
          <MantraAccordionPanel
            key={`panel-${idx}-${title}`}
            startOpen
            title={
              <Text.h3 id={title} className="mr4">
                {title}
              </Text.h3>
            }
          >
            <Component
              verticalDisplay
              organizationId={organizationId}
              editable={editable}
              handbookInfo={handbookInfo}
              selectedField={selectedField}
              editableSectionRowInfo={editableSectionRowInfo}
              onCancelEdit={() => setSelectedField(null)}
              onStartEdit={(fieldKey: keyof HandBookInfo) => setSelectedField(fieldKey)}
              onSave={onSave}
            />
          </MantraAccordionPanel>
        ))}
      </MantraAccordion>
    </HandbookDiv>
  );
}

const HandbookDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .mantra-accordion {
    margin-right: 2.5rem;
  }

  .mantra-accordion-panel {
    background-color: ${colors.white};
    border: 1px solid ${colors.grey.lightBorder};
    padding: 2rem;
  }

  .mantra-accordion-panel-content {
    padding-top: 0;
    padding-bottom: 0;

    &.panel-open {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

const HandbookNavDiv = styled.div`
  min-width: 18rem;
  position: sticky;
  top: 2em;
  height: auto;
`;
