/* eslint-disable react/jsx-curly-brace-presence */
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from 'baseui/modal';
import { KIND } from 'baseui/notification';
import { isEmpty, mapValues, omitBy, pick } from 'lodash';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Modal as MantraModal } from '../styles';
import { useEvents } from '../../../Components/Events/EventsProvider';
import { FinalButton } from '../../../Components/FinalButton';
import {
  ControlLabel,
  ControlHint,
  DateInputRHF,
  FormControl,
  InputRHF,
  PhoneInputRHF,
  SelectRHF,
  ZipInputRHF,
} from '../../../Components/Form';
import {
  getEmailValidator,
  validatePhoneNumber,
} from '../../../Components/FormValidations/Validators';
import { CenteredNotification } from '../../../Components/Notification';
import { isMantraAdmin, PermsOnly, useCurrentProvider } from '../../../Components/Permissions';
import { colors, Text } from '../../../globalStyles';
import {
  AddressInput,
  CareStatus,
  EditUser,
  PaymentSource,
  SamlConfigurationType,
  useAdminEditUserMutation,
  useEditPatientInfoQuery,
  useSamlConfigurationQuery,
} from '../../../graphQL';
import { stateOptions } from '../../../states';
import { borderRadius, formatPhoneNumber, stripPhoneNumber } from '../../../utils';
import { useDrilldownContext } from '../helpers';
import { Divider, SectionHeader } from '../Widgets/Styles';
import { DeleteUserModal } from './DeleteUserModal';

const labelSize = 'f6';

interface EditPatientInfoProps {
  closeModal: () => void;
}

export type EditPatientInfoFormValues = Omit<EditUser, 'careFlows'> & AddressInput;
const addressFields = ['line1', 'line2', 'city', 'state', 'zip'];

export const EditPatientInfo = ({ closeModal }: EditPatientInfoProps) => {
  const { track } = useEvents();

  const { currentProvider } = useCurrentProvider();

  const [editUser, { error }] = useAdminEditUserMutation({
    onCompleted: async editUserData => {
      await refetch();
      form.reset();
      setSaving(false);
      closeModal();
      track('user.info.edited', { userId: editUserData.adminEditUser.id });
    },
    onError: () => {
      onError();
      setSaving(false);
    },
  });
  const { user, refetch } = useDrilldownContext();

  const { data: samlConfigurationQueryData } = useSamlConfigurationQuery({
    variables: {
      organizationId: user.organization?.id ?? -1,
      type: SamlConfigurationType.User,
    },
  });

  const { data } = useEditPatientInfoQuery({ variables: { userId: user.id } });
  const [saving, setSaving] = useState(false);
  const [showSoftDeleteModal, setShowSoftDeleteModal] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const orgs = data?.organizations ?? [];

  const orgOptions = [
    { id: -1, label: 'Mantra Health' },
    ...orgs
      .filter(i => !i.children.length)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(i => ({ id: i.id, label: i.name })),
  ];

  const defaultValues: Partial<EditPatientInfoFormValues> = {
    ...pick(user.primaryAddress, ...addressFields),
    preferredName: user.preferredName,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone ? formatPhoneNumber(user.phone) : undefined,
    providerId: user.provider?.id ?? null,
    therapistId: user.therapist?.id ?? null,
    email: user.email,
    secondaryState: user.secondaryState,
    birthDate: user.birthDate ? moment(user.birthDate).format('MM/DD/YYYY') : undefined,
    samlUniqueId: user.samlUniqueId,
  };

  const form = useForm<EditPatientInfoFormValues>({
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const submit = async (values: EditPatientInfoFormValues) => {
    setSaving(true);
    const hasAddress = !isEmpty(
      omitBy(pick(values, addressFields), v => !v || (typeof v === 'string' && !v.trim()))
    );
    await editUser({
      variables: {
        editUser: {
          firstName: values.firstName?.trim(),
          lastName: values.lastName?.trim(),
          preferredName: values.preferredName?.trim(),
          primaryAddress: hasAddress ? pick(values, addressFields) : null,
          phone: values.phone ? stripPhoneNumber(values.phone) : null,
          email: values.email,
          id: user.id,
          organizationId: values.organizationId === -1 ? null : values.organizationId,
          dtc: values.organizationId === -1,
          secondaryState: values.secondaryState ?? null,
          birthDate: values.birthDate ?? null,
          samlUniqueId: values.samlUniqueId ?? null,
        },
      },
    });
  };

  const onError = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView();
    }
  };

  const handleSubmit = form.handleSubmit(submit, onError);
  const values = form.watch(['organizationId', 'state']);

  let nonSponsoredWarning = '';
  const paymentSources = user.careFlows.map(f => f.paymentSource);
  if (values.organizationId && paymentSources.some(i => i && i !== PaymentSource.Organization)) {
    nonSponsoredWarning =
      'NOTE: A payment source for this patient is not currently University Sponsored. Please update below, if necessary.';
  }

  // this will need to be updated to handle careFlowV2
  function requiredIfNotCancelledFactory<T>(validator?: (v: T) => true | string) {
    const isCancelled = () => form.getValues('careStatus') === CareStatus.Cancelled;

    // on a validation result, string is failure.
    return (v: T) => {
      if (!validator || !v) {
        return !!v || isCancelled() || 'This field is required.';
      }
      return validator(v);
    };
  }

  return (
    <Modal
      isOpen
      onClose={closeModal}
      closeable
      animate
      size="auto"
      role={ROLE.dialog}
      overrides={{
        Dialog: {
          style: {
            ...borderRadius('4px'),
            paddingLeft: '4rem',
            paddingRight: '4rem',
            paddingTop: '2rem',
            paddingBottom: '2rem',
          },
        },
      }}
      unstable_ModalBackdropScroll
    >
      <ModalHeader>
        <Text.h2 className="mb4">Edit Patient Info</Text.h2>
      </ModalHeader>
      {error && <CenteredNotification kind={KIND.negative}>{error.message}</CenteredNotification>}
      <ModalBody>
        <FormProvider {...form}>
          <form style={{ width: '325px' }} onSubmit={handleSubmit} ref={formRef}>
            {!isMantraAdmin(currentProvider) && (
              <FormControl name="fullname" label="Legal Name" labelSize={labelSize}>
                <div>
                  {user.firstName} {user.lastName}
                </div>
              </FormControl>
            )}
            <PermsOnly allowed="mantraAdmin">
              <InputRHF
                type="text"
                id="firstName"
                name="firstName"
                controlProps={{ label: 'First Name', labelSize }}
                placeholder="First Name"
              />
            </PermsOnly>
            <InputRHF
              type="text"
              name="preferredName"
              controlProps={{
                label: (
                  <>
                    <ControlLabel>Preferred Name</ControlLabel>
                    <ControlHint>
                      This is the first name that will be displayed on patient’s profile and the one
                      the patient would like to be addressed by. However, the legal name is still
                      required for telehealth. Note: the patient will see the preferred name on
                      their profile.
                    </ControlHint>
                  </>
                ),
                labelSize,
              }}
              placeholder="Preferred Name"
            />
            <PermsOnly allowed="mantraAdmin">
              <InputRHF
                type="text"
                id="lastName"
                name="lastName"
                controlProps={{ label: 'Last Name', labelSize }}
                placeholder="Last Name"
              />
            </PermsOnly>
            <PermsOnly allowed="mantraAdmin">
              <DateInputRHF
                name="birthDate"
                controlProps={{ label: 'Birth Date', labelSize }}
                placeholder="MM/DD/YYYY"
              />
            </PermsOnly>
            <PermsOnly allowed="mantraAdmin">
              <SelectRHF
                defaultValue={user.organization?.id ?? -1}
                name="organizationId"
                rules={{
                  validate: {
                    emailMatches: id => {
                      const org = orgs.find(i => i.id === id);
                      const domain = form.getValues('email')?.split('@')[1];
                      if (org && !org.emailDomains.includes(domain as string)) {
                        return 'Patient email address is not eligible to join the selected organization.';
                      }
                      return true;
                    },
                    paymentSourceMismatch: id => {
                      if (
                        id === -1 &&
                        user.careFlows.some(f => f.paymentSource === PaymentSource.Organization)
                      ) {
                        return "This patient currently has a payment source of 'University Sponsored', which is unavailable to patients that are not part of an organization. Please update the payment source(s) below.";
                      }
                      return true;
                    },
                  },
                }}
                clearable={false}
                options={orgOptions}
                controlProps={{
                  errorMsg: !form.errors?.organizationId?.message ? nonSponsoredWarning : undefined,
                  label: 'Organization',
                  labelSize,
                }}
              />
            </PermsOnly>
            {samlConfigurationQueryData?.organization.samlConfiguration
              ?.enableManualUniqueIdEdits && (
              <PermsOnly allowed="mantraAdmin">
                <InputRHF
                  type="text"
                  id="samlUniqueId"
                  name="samlUniqueId"
                  controlProps={{ label: 'Unique Id', labelSize }}
                  placeholder="Insert unique id here"
                />
              </PermsOnly>
            )}
            <FormControl name="risklevel" label="Risk Level" labelSize={labelSize}>
              <div>{user.assessmentInfo?.riskLevel || 'Unknown'}</div>
              <AttentionContainer>
                <Text.bodySmallBold>
                  {`You can change the risk level by submitting a "Risk Change" note`}
                </Text.bodySmallBold>
              </AttentionContainer>
            </FormControl>
            <Divider />
            <InputRHF
              type="text"
              name="email"
              placeholder={defaultValues.email ?? ''}
              rules={{
                validate: mapValues(
                  getEmailValidator(orgs.find(o => o.id === form.getValues('organizationId'))),
                  requiredIfNotCancelledFactory
                ),
              }}
              controlProps={{ label: 'Email', labelSize }}
            />
            <PhoneInputRHF
              name="phone"
              rules={user.phone ? { validate: validatePhoneNumber } : undefined}
              placeholder={defaultValues.phone ?? '1234567890'}
              controlProps={{ label: 'Phone Number', labelSize }}
            />
            <SectionHeader className="mt4 mb3">Address</SectionHeader>
            <InputRHF
              type="addressLine1"
              name="line1"
              placeholder="Address Line 1"
              controlProps={{ label: 'Address Line 1', labelSize }}
              rules={{
                maxLength: { value: 35, message: 'Please shorten the address to 35 characters' },
                // address has to be 35 characters or less because of dosespot rules D:
              }}
            />
            <InputRHF
              type="addressLine2"
              name="line2"
              placeholder="Address Line 2"
              controlProps={{ label: 'Address Line 2', labelSize }}
              rules={{
                maxLength: { value: 35, message: 'Please shorten the address to 35 characters' },
                // address has to be 35 characters or less because of dosespot rules D:
              }}
            />
            <InputRHF type="city" name="city" placeholder="City" controlProps={{ label: 'City' }} />
            <SelectRHF
              name="state"
              controlProps={{ label: 'State', labelSize }}
              options={stateOptions}
              clearable={false}
            />
            <ZipInputRHF
              name="zip"
              placeholder={defaultValues.zip ?? 'Zip'}
              controlProps={{ label: 'Zip', labelSize }}
            />
            <SelectRHF
              name="secondaryState"
              options={stateOptions}
              clearable={false}
              controlProps={{ label: 'Secondary Residential State', labelSize }}
            />
            <FinalButton
              className="w-100"
              loading={saving}
              kind="primary"
              type="submit"
              onClick={handleSubmit}
            >
              Save Patient Info
            </FinalButton>
          </form>
          <PermsOnly allowed="mantraAdmin">
            <DeleteUserModal
              showSoftDeleteModal={showSoftDeleteModal}
              setShowSoftDeleteModal={setShowSoftDeleteModal}
            />
            <ModalFooter>
              <MantraModal.dangerLink
                onClick={() => {
                  setShowSoftDeleteModal(true);
                }}
              >
                <Text.body>Duplicate student accounts?</Text.body>
                Delete this account
              </MantraModal.dangerLink>
            </ModalFooter>
          </PermsOnly>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

const AttentionContainer = styled.div`
  border-radius: 5px;
  background-color: ${colors.friendlyWarningBg};
  margin-top: 0.25rem;
  padding: 20px;
`;
