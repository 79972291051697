import React from 'react';
import { CareType, Entitlement, OrganizationForEnrollPageQuery } from '../../../graphQL';
import * as Styles from '../styles';
import { getReferralCreditBreakdown } from '../../../Components/ReferralCredits/getReferralCreditBreakdown';
import { EnrollOption } from './EnrollOption';
import { useHistory } from 'react-router-dom';
import { McpOnly } from '../../../Components/Permissions/Only';
interface EnrollOptionsProps {
  organization: OrganizationForEnrollPageQuery['organization'];
  setShowSelectionScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function EnrollOptionsComponent({
  organization,
  setShowSelectionScreen,
}: EnrollOptionsProps) {
  const history = useHistory();
  const entitlementsArray = organization.entitlements.map(entitlement => entitlement.key);

  const wholeCampusCare = entitlementsArray.includes(Entitlement.WholeCampusCare);
  const careTypes = organization.careFlows.map(careFlow => careFlow.careType);

  const { availableCredits } = getReferralCreditBreakdown({
    wholeCampusCare,
    orgReferralCreditData: organization.referralCredits,
    careTypes,
  });

  return (
    <Styles.EnrollOptionsWrapper>
      {entitlementsArray.includes(Entitlement.AllowMultipleReferralTypes) && (
        // This is the "default" option, and should be included in all referral types if the AllowMultipleReferralTypes entitlement is on
        // (unless product specifies otherwise)
        // If the entitlement is off, providers won't even see this page
        <EnrollOption
          title={
            careTypes.includes(CareType.Psychiatry && CareType.Therapy)
              ? 'Virtual Therapy and/or Psychiatry'
              : careTypes.includes(CareType.Psychiatry)
              ? 'Virtual Psychiatry'
              : 'Virtual Therapy'
          }
          label="Mantra Health"
          testId="selectDefaultReferralType"
          showCreditsRemaining={wholeCampusCare}
          availableCredits={availableCredits}
          onClick={() => setShowSelectionScreen(false)}
        />
      )}
      {entitlementsArray.includes(Entitlement.AllowCharlieHealthIopReferralsViaMcp) && (
        <McpOnly>
          <EnrollOption
            title="Virtual Intensive Outpatient Program"
            label="In Partnership with Charlie Health"
            testId="selectCharlieHealthIOPReferralType"
            description="Evidence-based programs for young people in need of a higher level of support, with comprehensive, personalized treatment plans alongside peers with common goals. 100% virtual."
            // onClick changes the page instead of state (like in the default option above)
            // That way you don't have to go through the enroll selection screen every single time if its a direct-link
            // Its a completely self-contained page
            // I recommend future entitlement-based referal options to be done this way
            onClick={() =>
              history.push(`/organizations/${organization.id}/students/enroll-option-iop`)
            }
          />
        </McpOnly>
      )}

      {entitlementsArray.includes(Entitlement.AllowTogetherAllReferralsViaMcp) && (
        <McpOnly>
          <EnrollOption
            title="Online Peer Support"
            label="In Partnership with TOGETHERALL"
            testId="selectTogetherallReferralType"
            description="Togetherall is a safe, online community to share feelings anonymously and get support to improve mental health and wellbeing. Safely monitored by licensed & registered mental health practitioners."
            onClick={() =>
              history.push(`/organizations/${organization.id}/students/enroll-togetherall`)
            }
          />
        </McpOnly>
      )}
    </Styles.EnrollOptionsWrapper>
  );
}
