import moment, { Moment } from 'moment-timezone';
import { useMemo, useState } from 'react';
import {
  AdminAvailabilityQuery,
  CareType,
  StateCodes,
  useAdminAvailabilityQuery,
  useAdminOrgIntakeAvailabilityQuery,
} from '../../../../graphQL';
import { guessTz } from '../../../../utils';
import { useDefaultProviderOrgParam } from '../../hooks/useDefaultParams';
import { BookingArgs, Slot } from '../types';

type AvailabilityArgs = {
  variables: {
    providerId: number;
    careType: CareType;
    forDays: Moment[];
    forBooking?: BookingArgs;
    patientState?: StateCodes;
  };
  skip?: boolean;
};

export const useProviderAvailability = ({
  variables: { providerId, careType, forDays, forBooking, patientState },
  skip,
}: AvailabilityArgs) => {
  const { user, duration, appointmentType } = forBooking ?? {};
  const organizationId = useDefaultProviderOrgParam();

  const start = forDays[0];
  const end = forDays[forDays.length - 1].clone().endOf('day');
  const timezone = start.tz() ?? guessTz();

  const [hoveredTime, setHoveredTime] = useState<Moment | null>(null);

  const {
    data: bookingData,
    loading: bookingLoading,
    error: bookingError,
  } = useAdminAvailabilityQuery({
    variables: {
      providerIds: [providerId],
      duration: duration!,
      appointmentType: appointmentType!,
      start: start.format(),
      end: end.format(),
      userId: user?.id!,
      careType,
      timezone,
      patientState: patientState || (user?.primaryAddressState as StateCodes | undefined),
    },
    skip: skip || !forBooking,
  });

  const {
    data: orgAvailData,
    loading: orgAvailLoading,
    error: orgAvailError,
  } = useAdminOrgIntakeAvailabilityQuery({
    variables: {
      providerIds: [providerId],
      organizationId: organizationId!,
      start: start.format(),
      end: end.format(),
      careType,
      timezone,
      patientState: patientState || (user?.primaryAddressState as StateCodes | undefined),
    },
    skip: skip || !!forBooking,
  });

  const availabilityData = forBooking
    ? bookingData?.adminAvailability
    : orgAvailData?.adminOrgIntakeAvailability;

  const hoverDuration = forBooking ? duration : orgAvailData?.adminOrgIntakeAvailability.duration;
  const hoveredEnd = hoveredTime ? hoveredTime.clone().add(hoverDuration, 'minutes') : null;

  const timesByDay = useMemo(() => {
    if (!availabilityData) return [];
    return getTimeslotsByDay({
      availabilities: availabilityData.data,
      interval: availabilityData.interval,
      start,
      end,
      forDays,
      timeZone: timezone,
    });
  }, [start, end, timezone, forDays, availabilityData]);

  const loading = bookingLoading || orgAvailLoading;
  const error = bookingError || orgAvailError;

  return { timesByDay, loading, hoveredEnd, error, setHoveredTime, hoveredTime };
};

type GetTimeslotArgs = {
  availabilities: AdminAvailabilityQuery['adminAvailability']['data'];
  start: Moment;
  end: Moment;
  forDays: Moment[];
  timeZone: string;
  interval: number;
};

const getTimeslotsByDay = ({
  availabilities,
  start,
  end,
  forDays,
  timeZone,
  interval,
}: GetTimeslotArgs): Slot[][] => {
  const slotLookup = new Map<number, Slot>();
  const runner = moment(start).tz(timeZone);

  while (runner <= end) {
    const time = runner.clone();
    slotLookup.set(time.valueOf(), { time, available: false, blockers: [], organizationId: null });
    runner.add(interval, 'minutes');
  }

  // Mark the timeslots which are available as such
  for (const availability of availabilities) {
    const time = moment(availability.start).clone().tz(timeZone);
    slotLookup.set(time.valueOf(), {
      time,
      available: true,
      blockers: availability.blockers,
      organizationId: availability.organizationId,
      isFeeForServiceTime: availability.isFeeForServiceTime ?? false,
    });
  }

  const slots = [...slotLookup.values()];

  // Map timeslots to days
  const timesByDay = forDays.map(day => slots.filter(({ time }) => time.date() === day.date()));
  // Sort timeslots within each day
  timesByDay.forEach(day => day.sort((a, b) => a.time.diff(b.time)));

  return timesByDay;
};

export default useProviderAvailability;
