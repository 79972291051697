import { isNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useEvents } from '../../Components/Events/EventsProvider';
import { Select } from '../../Components/Form';
import { PillLabel } from '../../Components/Labels';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { isMantraAdmin, PermsOnly, useCurrentProvider } from '../../Components/Permissions';
import { Tooltip } from '../../Components/Tooltip';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import { PaddedPage, Text } from '../../globalStyles';
import {
  Permission,
  ReportScope,
  useOrganizationCarePeriodsQuery,
  useOrganizationsSimpleQuery,
} from '../../graphQL';
import { isReportingDisabled } from '../../utils';
import { PageTitle } from '../Shared';
import { useOrgReport } from './hooks';
import { BannerItem, DashboardContainer, DashboardItem } from './Styles';
import { DiagnosesWidget } from './Widgets/Diagnoses';
import { WidgetProps } from './Widgets/interface';
import { PatientMonitoringWidget } from './Widgets/PatientMonitoring';
import { PatientTickers } from './Widgets/PatientTickers';
import { ReferralsListWidget } from './Widgets/ReferralsList';
import { VisitAttendance } from './Widgets/VisitAttendance';
import { VisitSatisfaction } from './Widgets/VisitSatisfaction';

export const Dashboard = () => {
  const metrics = useEvents();
  const history = useHistory();
  const { currentProvider } = useCurrentProvider();

  const params = useParams<{ organizationId: string }>();
  const orgId = numberOrUndefined(params.organizationId);
  const scope = getScope(orgId);

  const [carePeriodId, setCarePeriodId] = useState<number>();

  useEffect(() => {
    metrics.track('reporting.dash.enter');
    return () => metrics.track('reporting.dash.exit');
  }, [metrics]);

  const { data: orgData, loading: orgLoading } = useOrganizationsSimpleQuery({
    skip: !isMantraAdmin(currentProvider),
  });

  const { data, loading } = useOrgReport({ orgId, scope, carePeriodId });

  if (isReportingDisabled(currentProvider)) {
    return <Redirect to={`/organizations/${orgId}/students`} />;
  }

  const Content = () => {
    if (loading || orgLoading) return <LoadingPage className="mt5" />;
    if (!data) return null;
    return <OrganizationDashboardContent scope={scope} data={data} organizationId={orgId} />;
  };

  const organizationOptions = orgData?.organizations.map(i => ({ id: i.id, label: i.name })) ?? [];

  return (
    <PaddedPage style={{ backgroundColor: '#F9F9F9' }}>
      {/* anchor for logo */}
      <div className="relative">
        {orgId && orgId > 0 && <UniversityLogo organizationId={orgId} right="0" />}
        <div className="flex flex-row items-center mb3">
          <PageTitle className="mr3">Reporting</PageTitle>
          <PillLabel>
            <Text.label>Beta</Text.label>
          </PillLabel>
        </div>
      </div>
      {/* Mantra Admins Can Select Organization To View */}
      <PermsOnly allowed={Permission.MantraAdmin}>
        <div className="mv3">
          <Text.bodyBold className="mb1">Organization</Text.bodyBold>
          <div className="flex">
            <div className="w5">
              <Select
                clearable={false}
                value={orgId}
                onChange={id => {
                  setCarePeriodId(undefined);
                  history.push(`/organizations/${id}/reporting`);
                }}
                options={[
                  { label: 'All (Organizations & DTC)', id: null },
                  { label: 'DTC', id: -1 },
                  ...organizationOptions,
                ]}
              />
            </div>
            {orgId && orgId > 0 && (
              <div className="w5 pl3 flex items-center">
                <CarePeriodSelector
                  organizationId={orgId}
                  onSelect={v => setCarePeriodId(v)}
                  value={carePeriodId}
                />
                <span className="ml2">
                  <Tooltip content="Note: Care Period selection is for admin use only. Currently this only changes the data for the visit attendence module" />
                </span>
              </div>
            )}
          </div>
        </div>
      </PermsOnly>
      <Content />
    </PaddedPage>
  );
};

type DashContentProps = WidgetProps & { organizationId?: number };
export const OrganizationDashboardContent = ({ data, scope, organizationId }: DashContentProps) => {
  return (
    <DashboardContainer>
      <BannerItem>
        <PatientTickers data={data} scope={scope} />
      </BannerItem>
      <DashboardItem>
        <PatientMonitoringWidget data={data} scope={scope} />
        <ReferralsListWidget data={data} scope={scope} />
        <DiagnosesWidget organizationId={organizationId} scope={scope} />
      </DashboardItem>
      <DashboardItem>
        <VisitAttendance data={data} scope={scope} />
        <VisitSatisfaction data={data} scope={scope} />
      </DashboardItem>
    </DashboardContainer>
  );
};

// Number(val) returns NaN if not a number string
const numberOrUndefined = (stringVal?: string) => {
  if (!stringVal) return undefined;
  const val = Number(stringVal);
  return Number.isNaN(val) ? undefined : val;
};

const getScope = (orgId?: number) => {
  if (!isNumber(orgId)) return ReportScope.All;
  if (orgId < 0) return ReportScope.Dtc;
  return ReportScope.Org;
};

type CarePeriodSelectorProps = {
  organizationId: number;
  onSelect: (orgId?: number) => void;
  value?: number;
};
const CarePeriodSelector = ({ organizationId, onSelect, value }: CarePeriodSelectorProps) => {
  const { data } = useOrganizationCarePeriodsQuery({ variables: { organizationId } });

  const sortedData =
    data?.organization.carePeriods.sort(
      (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    ) ?? [];

  return (
    <Select
      clearable
      value={value}
      onChange={v => onSelect(v as number)}
      placeholder="Care Period"
      options={sortedData.map(c => ({
        id: c.id,
        label: `${c.startDate} - ${c.endDate}`,
      }))}
    />
  );
};
